@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
 

}

html{
  scroll-behavior: smooth; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .my-work:after {
  content: '';
  border: 0.5px solid rgba(187, 252, 252, 0.97); 
  margin-left: 30px;
  height: 50px;
  
}
.my-work::before {
  content: '';
  border: 0.5px solid rgba(187, 252, 252, 0.97); 
  margin-right: 30px;
  
} */
.hire-us{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 18px;

}
.getQuote{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 157px;
  height: 50px;
  color: white;
  border-radius: 18px;
  background: linear-gradient(82deg, #092D2D 0.33%, rgba(10, 83, 83, 0.17) 100.33%);
  border: 1px solid rgba(136, 177, 177, 0.44);
}
.myfont{
  font-family: 'Montserrat', sans-serif;
}
.design-company{
  background: linear-gradient(93deg, #96FFFF 4.91%, #036A6A 95.87%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Montserrat;
font-style: normal;
font-weight: 400;
}

.we{
  background: linear-gradient(281deg, #9BDEDE 0.41%, #036A6A 98.32%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.slick-dots{
  background-image: linear-gradient(276deg, rgba(160, 248, 248, 0.05) 1.94%, rgba(84, 90, 90, 0.09) 96.31%) !important;
  width: fit-content !important;
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  bottom: -50px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 20px !important;
  backdrop-filter: blur(10px) !important;
  padding-bottom: 5px !important;
}
.my_slider .slick-dots li button:before{
  color: white !important;
}

.services_image{
  background-image: url(/public/group.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-clip: content-box;
}

@media (max-width:800px) {
  .services_image{
    background-image: none;
    background: black;
  }
}